import PropTypes from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Main extends React.Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state =
    {
      formError: false
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    let self = this;
    if (e.target.name.value === "" || e.target.email.value === "" || e.target.message.value === "") {
      this.setState({formError: "You must fill out all of the fields."})
    } else if (!validateEmail(e.target.email.value)) {
      this.setState({formError: "You seem to have entered an invalid email address."})
    } else {

      let data = {
        "form-name": "Contact",
        name: e.target.name.value,
        email: e.target.email.value,
        message: e.target.message.value
      }

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(data)
      })
        .then((response) => {
          this.props.onSwitchArticle('thanks')
        })
        .catch(error => self.setState({formError: "An unknown error occurred. " + error}))
    }
  }

  render() {
    let close = (
      <button
        aria-label="Close"
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={(e) => {
          if(e.which === 13)
            this.props.onCloseArticle()
          return false
        }}
      >Close</button>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="article-heading">Get in Touch</h2>
          <form onSubmit={this.handleSubmit} method="post" action="" name="Contact" data-netlify="true">
            <input type="hidden" name="form-name" value="Contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            {this.state.formError ? <div>{this.state.formError}</div> : null }
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://twitter.com/ryanbenhase" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} style={{color:"#ffffff"}}/>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/rybenhase" target="_blank" rel="noreferrer" >
                <FontAwesomeIcon icon={faLinkedin} style={{color:"#ffffff"}}/>
              </a>
            </li>
            <li>
              <a href="https://instagram.com/ryanbenhase" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} style={{color:"#ffffff"}}/>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="thanks"
          className={`${this.props.article === 'thanks' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Thank You!</h2>
          <p>
            Your message is en route to my inbox.
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
